.site-header {
  &--ign {
		background-color: $white;
		.site-header {
			&__logo {
				svg {
					width: 26rem;
					height: auto;
					margin-top: .375rem;
				}
			}
			&__nav-btn {
				&__l {
					background-color: $ign_linkBlue;
				}
			}
			&__nav {
        &__item {
          @include h4-font;
          font-weight: bold;
        }
				&__link {
					color: $ign_linkBlue;
          &--selected {
            color: $ign_richBlack;
          }
				}
				@include breakpoint($nav-breakpoint) {
					&__links {
						//margin-right: 0;
					}
          &__share {
            margin-right: 0;
            &__network {
              &:last-child {
                a {
                  margin-right: 0;
                }
              }
            }
            &__icon {
              path {
                fill: $ign_linkBlue_50 !important;
              }
              &:hover path {
      					fill: $ign_linkBlue !important;
      				}
            }
          }
				}
			}
		}
	}
}
