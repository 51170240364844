.event-contact {
    width: auto;
    max-width: 100.5rem;
    margin: 0 auto;
    padding: 0 2rem;

    @include breakpoint(768px) {
        padding: 0 3rem;
	}
    
    h2 p {
        @include h3-font;
        margin-bottom: 6rem;
    }

    .contact-details{
        margin-bottom: 4rem;

        p {
            @include body-font;
        }

    }
}