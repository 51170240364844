.landing-page-header {
	@extend %section-padding;
	&__heading {
		@extend %gutters;
		@include h1-font;
	}
	&__sub-heading {
		@extend %gutters;
		@include h5-font;
		color: $cpp-grey;
	}
	&__standfirst {
		@extend %gutters;
		color: $cpp-grey;
		@include top-short-rule;
		max-width: $max-text-width;
		@include body-font;
		p {
			color: $cpp-grey;
			@include body-font;
			margin-bottom: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				@include link;
			}
		}
	}
	&--light-blue {
		background: $light-blue;
	}
}