.image-text-table-wrap {
	@extend %text-column;
	@extend %gutters;
}
.image-text-table {
	&__row:not(:first-child) {
		border-top: 1px solid $cpp-grey-50;
	}
	&__cell {
		display: block;
		&:nth-child(1) {
			padding: 6rem 0 3rem;
		}
		&:nth-child(2) {
			padding: 0 0 6rem;
		}
		:last-child {
			margin-bottom: 0;
		}
	}
	&__row:first-child &__cell:first-child {
		padding-top: 0;
	}
	&__stat {
		@include stat-font;
		color: $cpp-grey;
		text-align: center;
		position: relative;
		top: 1rem;
	}
	&__image {
		width: 180px;
		margin-left: auto;
		margin-right: auto;
	}
	@include breakpoint(600px) {
		&__cell {
			display: table-cell;
			vertical-align: middle;
			padding-top: 5rem !important;
			padding-bottom: 5rem !important;
			&:nth-child(1) {
				padding-right: $gutter-half-width-medium;
				width: 33.333333%;
			}
			&:nth-child(2) {
				padding-left: $gutter-half-width-medium;
				width: 66.666666%;
			}
		}
		&__row:first-child &__cell {
			padding-top: 0 !important;
		}
		&__row:last-child &__cell {
			padding-bottom: 0 !important;
		}
		&__image {
			width: 100%;
		}
	}
}