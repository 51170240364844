.body--home {
	.section.section__standfirst {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
}

.home-standfirst {
	@extend %gutters;

	&__standfirst {
		@include h3-font;
		color: #fff;
	}

	&__awards-logo {
		margin-top: 2rem;

		.awards-logo {
			width: auto;
			max-width: 144px;
			max-height: 144px;
		}
	}

	@include breakpoint(large) {
		display: flex;
		flex-wrap: wrap;

		&__standfirst {
			flex: 0 0 83.333333%;
		}

		&__awards-logo {
			margin-top: 0;
			flex: 0 0 83.333333%;
	
			.awards-logo {
				margin-left: auto;
				margin-right: 0;
			}
		}
	}
}

.home-content {
	margin-top: -2rem;
	@extend %gutters;
	@include breakpoint(large) {
		width: 83.333333%;
	}
}
