.main-content--ign {
  border-top: 1px solid $cpp-grey;
  .section, .landing-page-header {
    &--ign_richBlack {
      background-color: $ign_richBlack;
    }
    &--ign_lightBlue {
      background-color: $ign_lightBlue;
    }
    &--ign_lightBlue_30 {
      background-color: $ign_lightBlue_30;
    }
    &--light-peach-40 {
      background-color: $light-peach-40;
    }
    &--ign_green_20 {
      background-color: $ign_green_20;
    }
  }
  .section {
    &#programmeBlock, &#contactBlock {
      border-top: 1px solid $ign_lightBlue;
    }
    &__heading {
      color: $ign_richBlack;
      &--white {
        color: $white;
      }
    }
    &__content {
      padding-top: 0;
    }
  }
  .more-entries {
		background: $ign_lightBlue_30_solid;
	}
  .content-toggle {
    color: $ign_linkBlue;
    &:after {
      border-color: $ign_linkBlue transparent transparent;
    }
  }
  .read-report {
    background-color: $ign_richBlack_75;
  }
  .sub-nav {
    background-color: $ign_richBlack_75;
    .sub-nav-grid {
      .sub-nav-selected {
        color: $ign_green;
      }
    }
  }
}
.footer {
  &--ign {
    background-color: $ign_richBlack;
  }
}
