.newsletter {
	@extend %gutters;
	@extend %text-column;
	&__heading {
		@include h3-font;
		margin-bottom: 4rem;
		color: #fff;
	}

	@include breakpoint(medium) {
		display: flex;
    	justify-content: space-between;
		&__heading {
			margin-bottom: 0;
		}
		.btn {
			height: fit-content;
    		margin-left: 5rem;
			white-space: nowrap;
		}
	}
}