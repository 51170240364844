.standout-text {
	@extend %image-margins;
	@extend %text-column;
	&__text {
		@extend %gutters;
		p {
			@include pullquote-font;
			padding-left: 4rem;
			position: relative;
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 1rem;
				background: $cpp-grey;
				border-radius: .5rem;
			}
			@include breakpoint(smallish) {
				width: 75%;
			}
		}
	}
}