.entry-card-list {
	display: flex;
	flex-wrap: wrap;
	// these white borders cover up borders of item that's just off stage
	position: relative;
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		border-left: 1px solid #fff;
		top: 0;
		bottom: 0;
		left: -1px;
		z-index: 1;
		.section--cpp-grey-10 & {
			border-color: $cpp-grey-10;
		}
		.section--light-peach-40 & {
			border-color: #fde6d0;
		}
	}
	&:after {
		left: auto;
		right: 0;
	}
	&__no-results {
		@extend %gutters;
		margin-bottom: 7rem;
		@include bold-body-font;
		a {
			@include link;
		}
		p {
			margin-bottom: 1.5rem;
			@include bold-body-font;
			a {
				@include link;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&--light-blue {
		&:before, &:after {
			border-left: 1px solid $light-blue;
		}
	}
}
