.share {
	@extend %gutters;
	@extend %text-column;
	&__input {
		display: none;
	}
	&__success {
		background: $green;
		@include h4-font;
		color: #fff;
		padding: 1rem 3rem;
		border-radius: 3rem;
		float: left;
		clear: both;
		width: 100%;
		margin-top: -6rem;
		position: relative;
		text-align: center;
		box-shadow: 0 .5rem 0 #000;
	}
	@include breakpoint(medium) {
		&__input {
			display: block;
			@include h4-font;
			color: $cpp-grey;
			padding: 1rem 3rem;
			height: 6rem;
			border-radius: 3rem 0 0 3rem;
			border: none;
			width: calc(100% - 18rem);
			float: left;
		}
		.btn {
			border-radius: 0 3rem 3rem 0;
			box-shadow: none;
			width: 18rem;
			padding-left: 2rem;
			&:active {
				top: 0;
			}
		}
		&__success {
			box-shadow: none;			
		}
	}
}