.stat {
	&__figure {
		@include stat-font;
		color: $cpp-grey;
		position: relative;
		top: 1rem;
		padding-bottom: 1.5rem;
		word-wrap: break-word;
	}
	&__text {
		@include h6-font;
		color: $text-color;
	}
	.stats & {
		margin-left: 4rem;
		margin-right: 4rem;
		&:not(:last-child) {
			margin-bottom: 4rem;
		}
		@include breakpoint(500px) {
			padding-left: $gutter-half-width-small;
			width: 33.333333%;
			float: left;
			margin: 0 !important;
			&__figure {
				font-size: 10rem;
				line-height: 1;
			}
		}
		@include breakpoint(600px) {
			&__figure {
				font-size: 12rem;
			}
		}
		@include breakpoint(700px) {
			&__figure {
				font-size: 13rem;
			}
		}
		@include breakpoint(850px) {
			padding-left: $gutter-half-width-medium;
			&__figure {
				font-size: 14rem;
			}
		}
		@include breakpoint($huge-screen) {
			&__figure {
				font-size: 20rem;
			}
		}
	}
}
