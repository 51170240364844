.filters {
	padding: 3rem 0;
	background: $cpp-grey-10;
	&__heading {
		@extend %gutters;
		@include h5-font;
		color: $cpp-grey;
		cursor: pointer;
		float: left;
		&:after {
			display: inline-block;
			content: '';
			// arrow
			border-style: solid;
			border-width: 10px 8px 0 8px;
			border-color: $cpp-grey transparent transparent transparent;
			margin-left: 1rem;
			position: relative;
			top: -.25rem;
			transition: transform .4s;
		}
		&--active:after {
			transform: rotate(180deg);
		}
		&:hover {
			color: $text-color;
			&:after {
				border-color: $text-color transparent transparent transparent;
			}
		}
	}
	&__clear {
		@extend %tag;
		float: right;
		margin: 0 $gutter-half-width-small 0 0;
		background-color: $cpp-grey;
		color: #fff;
		&:hover {
			background-color: $text-color;
		}
		&__span {
			display: none;
			@include breakpoint(400px) {
				display: inline;
			}
		}
		@include breakpoint(medium) {
			padding: 0.5rem 2rem;
			border-radius: 2rem;
			margin: 0 $gutter-half-width-medium 0 0;
		}
	}
	&__panel {
		clear: both;
		.JS & {
			display: none;
		}
	}
	&__group {
		@extend %gutters;
		&__heading {
			@include h5-font;
			color: $cpp-grey;
			padding-top: 6rem;
			margin-bottom: 2rem;
		}
	}
	&__checkbox {
		display: none;
	}
	&__label {
		@extend %tag;
	}
	&__checkbox:not(:checked) + &__label {
		background: $light-blue;
		color: $slate-grey;
		&:hover {
			background: darken($light-blue, 5%);
		}
	}
	&__btn {
		@extend %gutters;
		clear: both;
		padding-top: 5rem;
		padding-bottom: 3rem;
	}
	@include breakpoint(large) {
		&__col {
			width: 33.333333%;
			float: left;
			&--two {
				width: 50%;
			}
		}
	}
}