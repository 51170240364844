.programme {
	@extend %text-column;
	@extend %gutters;
	&__time {
		@include bold-body-font;
		color: $slate-grey;
		width: 12rem;
		float: left;
		clear: both;
		border-top: 1px solid darken($light-blue-20, 10%);
		padding: 3rem 0;
	}
	&__text {
		margin-left: 12rem;
		border-top: 1px solid darken($light-blue-20, 10%);
		padding-top: 3rem;
		&:not(:last-child) {
			padding-bottom: 3rem;
		}
		&__heading {
			@include bold-body-font;
			color: $slate-grey;
		}
		&__body {
			margin-top: 3rem;
		}
		.content-toggle {
			margin-top: 2rem;
		}
	}

	@include breakpoint(large) {
		&__time {
			margin-left: 0rem;
		}
		// &__text {
		// 	margin-left: 0;
		// }
	}
}


.programme-subtext {
	@extend %text-column;
	@extend %gutters;

	p {
		@include small-text-font;
		margin-bottom: 4rem;
	}
} 

