.parallax {
	overflow: hidden;
	// .parallax doesn't have to contain .parallax__img – first child will always get parallaxed, whatever it is!
	& > * {
		padding-top: 3rem;
		margin-top: -3rem;
		position: relative;
	}
	// adjust these heights as needed
	&__img {
		height: 30rem;
		background-size: cover !important;
		@include breakpoint(smallish) {
			height: 45rem;
		}		
		@include breakpoint(medium) {
			height: 50rem;
		}		
		@include breakpoint(large) {
			height: 60rem;
		}		
		@include breakpoint(huge) {
			height: 75rem;
		}
	}
}