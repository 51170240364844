.people {
	@extend %text-column;
	@include clearfix;
	&:not(:last-child) {
		margin-bottom: 5rem;
	}
	&__img {
		@extend %gutters;
		border-radius: 50%;
		width: 20rem;
		margin-bottom: 3rem;
	}
	&__text {
		@extend %gutters;
	}
	&__name {
		@include h5-font;
		color: $cpp-grey;
		margin-bottom: 2rem;
		&__link {
			color: $cpp-grey;
			@include link;
		}
		&__twitter {
			display: inline-block;
			margin-left: 0.5rem;
			position: relative;
			top: .25rem;
			&:hover path {
				fill: $text-color;
			}
		}
	}
	&__role {
		@include small-text-font;
		text-transform: uppercase;
		color: $blue;
		margin-bottom: 2rem;
	}
	&__bio {
		margin-bottom: 2rem;
		p {
			@include small-text-font;
			color: $cpp-grey;
		}
		a {
			@include link;
		}
	}
	@include breakpoint(600px) {
		&__img {
			float: left;
			width: 25%;
		}
		&__text {
			margin-left: 25%;
		}
	}
}