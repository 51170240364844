.main-content--ign {
	.btn {
		color: $ign_richBlack;
		background-color: $ign_green;
		box-shadow: 0 .5rem 0 $ign_richBlack;
		&:hover {
			background-color: lighten($ign_green, 10%);
		}
	}
	.share {
		.btn {
			box-shadow: none;
		}
	}
	.filters {
		background: $ign_lightBlue_30;
		.filters__label {
			background: darken($ign_linkBlue_50, 15%);
			color: $ign_richBlack;
		}
		.filters__checkbox:not(:checked) + .filters__label {
			background: lighten($ign_linkBlue_50, 10%);
			color: $ign_richBlack;
			&:hover {
				background: darken($ign_linkBlue_50, 5%);
			}
		}
	}
}
