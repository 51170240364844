.main-content--ign {
	.people {
		&-heading, &__name, &__role, &__bio {
			color: $slate-grey;
			p {
				color: $slate-grey;
			}
		}
		&__name {
			svg {
				display: inline-block;
				path {
					fill: $ign_linkBlue;
				}
			}
		}
	}
	.section-wrap {
    &__people {
			position: relative;
      .people-heading {
				&__wrap {
					display: inline-block;
					&:before {
						content: '';
						position: absolute;
						display: block;
						left: 12px;
						right: 12px;
						border-top: 1px solid $cpp-grey-50;
						@include breakpoint(medium) {
							left: 48px;
							right: 48px;
						}
					}
					span {
						padding-top: 5rem;
						display: inline-block;
					}
				}
				&:first-child {
					.people-heading__wrap {
						&:before {
							content: none;
						}
						span {
							padding-top: 0;
							margin-left: 0;
						}
					}
				}
      }
      .people-one-three {
        &:after {
          content: '';
          display: block;
        }
        @include breakpoint(huge) {
          float: left;
          width: 33.333333%;
          padding-left: 24px;
          padding-right: 0;
          .people__img {
            width:25%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
			.twitter-link {
				display: inline-block;
				svg {
					width: 22px;
					height: 22px;
					path {
						fill: $ign_linkBlue;
					}
				}
			}
    }
  }
}
