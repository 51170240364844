html {
	font-size: 7px;
	-webkit-text-size-adjust: none; // prevent iOS from resizing small text
	@include breakpoint(768px) {
		font-size: 8px;
	}
}

/******************************************************************************************
WEBFONTS
******************************************************************************************/

@font-face {
	font-family: 'WJStats';
	src: url('/assets/fonts/WJStatsRegular.woff2') format('woff2'),
		url('/assets/fonts/WJStatsRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'InteloAlt';
	src: url('/assets/fonts/InteloAlt-Italic.woff2') format('woff2'),
		url('/assets/fonts/InteloAlt-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'InteloAlt';
	src: url('/assets/fonts/InteloAlt-BoldItalic.woff2') format('woff2'),
		url('/assets/fonts/InteloAlt-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'InteloAlt';
	src: url('/assets/fonts/InteloAlt-Regular.woff2') format('woff2'),
		url('/assets/fonts/InteloAlt-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'InteloAlt';
	src: url('/assets/fonts/InteloAlt-Bold.woff2') format('woff2'),
		url('/assets/fonts/InteloAlt-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}



/******************************************************************************************
FONT MIXINS
******************************************************************************************/

@mixin body-font {
	// 24/32px
	font-family: "freight-text-pro", Georgia, serif;
	font-size: 3rem;
	line-height: 4rem;
	font-weight: normal;
}
@mixin bold-body-font {
	// 23.2/32px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 2.9rem;
	line-height: 4rem;
	font-weight: bold;
}
@mixin h1-font {
	// 56/56px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 7rem;
	line-height: 7rem;
	font-weight: bold;
}
@mixin h2-font {
	// 48/52px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 6rem;
	line-height: 6.5rem;
	font-weight: bold;
}
@mixin h3-font {
	// 42/48px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 5.25rem;
	line-height: 6rem;
	font-weight: bold;
}
@mixin h4-font {
	// 22/32px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 2.75rem;
	line-height: 4rem;
	font-weight: normal;
}
@mixin h5-font {
	// 28/32px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 3.5rem;
	line-height: 4rem;
	font-weight: bold;
}
@mixin h6-font {
	// 18/24px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: bold;
}
@mixin small-text-font {
	// 18/24px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: normal;
}
@mixin pullquote-font {
	// 32/40px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 4rem;
	line-height: 5rem;
	font-weight: lighter;
}
@mixin stat-font {
	// 160/160px
	font-family: "WJStats", impact, sans-serif;
	font-size: 14rem;
	line-height: 14rem;
}
@mixin footnote-font {
	// 23.2/32px
	font-family: "InteloAlt", arial, sans-serif;
	font-size: 2.9rem;
	line-height: 4rem;
	font-weight: normal;
}
@mixin link {
	color: $purple;
	background-image: linear-gradient($purple 50%, rgba(0,0,0,0) 50%);
	background-repeat: repeat-x;
	background-size: 2px 2px;
	background-position: 0 1.15em;
	&:hover {
		color: $text-color;
		background-image: linear-gradient($text-color 50%, rgba(0,0,0,0) 50%);
	}
}
@mixin link-white {
	color: #fff;
	background-image: linear-gradient(#fff 50%, rgba(0,0,0,0) 50%);
	background-repeat: repeat-x;
	background-size: 2px 2px;
	background-position: 0 1.15em;
	&:hover {
		color: #fff;
		background-image: linear-gradient(#fff 50%, rgba(0,0,0,0) 50%);
	}
}
@mixin no-link {
	background: none;
}

/******************************************************************************************
TEXT STYLES
******************************************************************************************/

p, li {
	@include body-font;
	color: $text-color;
}
ul, ol {
	@include body-font;
	color: $text-color;
	margin-bottom: 0; // ULs just get bottom margin if they're in a .wysiwyg block
}
li {
	margin-bottom: 0;
	list-style: none;
}
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
a {
	text-decoration: none;
}
a:focus, a:active {
	outline: none;
}
img {
	max-width: 100%;
}
sup, sub {
	vertical-align: baseline;
	position: relative;
	top: -0.2em;
	font-size: .6em;
	line-height: 1em;
}
sub { 
	top: 0.2em; 
}
// our reset doesn't cover these!
figure, figcaption {
	margin: 0;
	padding: 0;
}
button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;	
}

/******************************************************************************************
WYSIWYG STYLES
******************************************************************************************/

.wysiwyg {
	p {
		margin-bottom: 4rem;
	}
	h2 {
		@include h5-font;
		margin: 6rem 0;
		color: $cpp-grey;
	}
	h3 {
		@include bold-body-font;
		margin-bottom: 4rem;
	}
	ul {
		margin-bottom: 4rem;
		ul {
			margin-left: 3rem;
		}
		& > li {
			padding-left: 3rem;
			position: relative;
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
			&:before {
				content: '';
				position: absolute;
				top: 1.25rem;
				left: 0;
				width: 1.5rem;
				height: 1.5rem;
				background: $cpp-grey;
				border-radius: 50%;
				margin: 0 .8rem 0 0;
			}
		}
	}
	ol {
		margin-bottom: 4rem;
		& > li {
			padding-left: 4rem;
			position: relative;
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
			&:first-child {
				counter-reset: index;
			}
			&:before {
				content: counter(index);
				counter-increment: index;
				position: absolute;
				left: 0;
			}
		}
		ul {
			margin: 2rem 0;
		}
	}
	a {
		@include link;
	}
	table {
		margin: 4rem 0;
		width: 100%;
		border-bottom: 1px solid $cpp-grey-10;
		tr {
			border-top: 1px solid $cpp-grey-10;
		}
		td, th {
			vertical-align: top;
			padding: 1.5rem 0;
			@include small-text-font;
			&:not(:last-child) {
				padding-right: 3rem;
			}
		}
		th {
			font-weight: bold;
		}
	}
	:first-child {
		margin-top: 0;
	}
	&--sans-serif {
		p, li {
			@include footnote-font;
		}
	}
	&--small {
		p, li {
			@include small-text-font;
			margin-bottom: 3rem;
		}
	}
	&--dont-break-urls a {
		// based on https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;

	}
}

/******************************************************************************************
FORMS
******************************************************************************************/

input, textarea, select, button {
	-webkit-appearance: none;
	border-radius: 0;
}
.input, .textarea, .btn, .select {
	border: none;
}
.select {
	display: inline-block;
	// remove select arrows
	-moz-appearance: none; text-indent: 1px; text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
	// add our own arrow
	&:after {
		content: '';
		display: inline-block;
		width: 0;
		border-style: solid;
		border-width: 1rem .6rem 0;
		border-color: #000 transparent transparent;
		margin-left: .9rem;
		position: relative;
		top: .05rem;
	}
}
// remove select arrows on IE
select::-ms-expand {
		display: none;
}