.sub-nav {
    display: block;
    position: relative;

    width: 100%;
    height: 7rem;
    top: 0;

    background-color: $purple;
    transition: all 0.6s;
    z-index: 100;

    overflow: hidden;

    @include breakpoint(640px) {
        height: 8rem;
	}

    .sub-nav-grid {
        display: grid;

        width: auto;
        max-width: 100rem;
        height: auto;

        padding-left: 3rem;
        padding-right: 3rem;
        margin: 0 auto;

        grid-template-columns: 1fr;
        grid-auto-rows: 7rem;

        grid-column-gap: 0rem;
        align-items: center;

        @include breakpoint(640px) {
            height: 8rem;
            grid-template-columns: 21rem repeat(4, min-content);
            grid-column-gap: 3rem;
        }

        .option-border {
            height: 100%;
        }

        .option-border:not(:first-child) {
            border-top: 2px solid $peach;
            @include breakpoint(640px) {
                border: none;
            }
        }

        p {
            @include h4-font;
            color: white;
            max-width: 30rem;
            transform: translateY(40%);

            @include breakpoint(640px) {
                max-width: unset;
            }
        }

        p:hover {
            cursor: pointer;
        }

        .sub-nav-option-hidden {
            display: none;
        }

        .sub-nav-selected {
            color: $peach;
        }
    }

    .placeholder-option {
        width: 30rem;
        height: 7rem;
        z-index: 100;
        background-color: $purple;
        position: absolute;
        top: 0;
        padding: 0 3rem;

        @include breakpoint(640px) {
            display: none!important;
        }

        p {
            @include h4-font;
            color: white;
            transform: translateY(40%);
        }

        p:hover {
            cursor: pointer;
        }

        .sub-nav-selected {
            color: $peach;
        }
    }

    .arrow {
        width: 3rem;
        height: 2rem;
        display: inline-block;
        position: absolute;
        margin: 0 1rem;
        top: 2.5rem;
        right: 2rem;

        @include breakpoint(640px) {
            display: none;
        }
    }
    .arrow span {
        position: absolute;
        top: 1rem;
        width: 1.8rem;
        height: 0.3rem;
        background-color: #efefef;
        display: inline-block;
        transition: all .2s ease;
    }
    .arrow span:first-of-type {
        left: 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
    }
    .arrow span:last-of-type {
        right: 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    .arrow:hover {
        cursor: pointer;
    }
}

.sub-nav-hidden {
    position: fixed;
    top: -8rem;
}

.sub-nav-fixed {
    position: fixed;
    top: 11rem;
}