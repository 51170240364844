$input-border-color: red;

%label {
	display: block;
	margin-bottom: 1rem;
	@include body-font;
	font-weight: 600;
	color: $text-color;
}
%textInput {
	display: block;
	border: 1px solid $input-border-color;
	width: 100%;
	padding: 1rem 1.5rem;
	@include body-font;
	box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, .08);
	margin-bottom: 3rem;
}
%textarea {
	display: block;
	border: 1px solid $input-border-color;
	width: 100%;
	padding: 1rem 1.5rem;
	height: 20rem;
	@include body-font;
	box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, .08);
	margin-bottom: 3rem;
}
%select {
	display: inline-block;
	padding: 1rem 6rem 1rem 1.5rem;
	border: 1px solid $input-border-color;
	@include body-font;
	color: $text-color;
	margin-bottom: 3rem;
	background: #fff url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ0IDQ4Ij48cGF0aCBmaWxsPSIjNDU0NTQ1IiBkPSJNMTUgMTloMTRsLTcgMTF6Ii8+PC9zdmc+) top right no-repeat;
	-moz-appearance: none; text-indent: 1px; text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
	// remove select arrows on IE
	&::-ms-expand {
		display: none;
	}
}
.form {
	@extend %text-column;
	@extend %gutters;
	margin: 6rem 0;
	.btn {
		margin-top: 2rem;
	}
	&__label {
		@extend %label;
		&--required:after {
			content: "*";
		}
	}
	&__textInput {
		@extend %textInput;
		&--no-border {
			border: none;
		}
	}
	&__textarea {
		@extend %textarea;
	}
	&__select {
		@extend %select;
	}
	&__radio {
		display: block;
		position: relative;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 3rem;
		}
		&__btn {
			width: 4rem;
			height: 4rem;
			position: absolute;
			left: 0;
			border: 1px solid $input-border-color;
			border-radius: 50%;
			cursor: pointer;
		}
		&__text {
			@include body-font;
			color: $text-color;
			margin-left: 5rem;
			cursor: pointer;
		}
		&__radio {
			opacity: 0;
			position: absolute;
			&:hover + div {
				border-color: $input-border-color;
			}
			&:focus + div {
				border-width: 2px;
			}
			&:checked + div {
				border-color: $input-border-color;
				&:after {
					content: '';
					width: 2rem;
					height: 2rem;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -1rem 0 0 -1rem;
					background: red;
					border-radius: 50%;
					display: block;
				}
			}
		}
	}
	&__checkbox {
		display: block;
		position: relative;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 3rem;
		}
		&__btn {
			width: 4rem;
			height: 4rem;
			position: absolute;
			left: 0;
			border: 1px solid $input-border-color;
			cursor: pointer;
		}
		&__text {
			cursor: pointer;
			@include body-font;
			color: $text-color;
			margin-left: 5rem;
		}
		&__checkbox {
			opacity: 0;
			position: absolute;
			&:hover + div {
				border-color: $input-border-color;
			}
			&:focus + div {
				border-width: 2px;
			}
			&:checked + div {
				background: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBmaWxsPSIjMDBhODcwIiBkPSJNMTIuOCAyNGwtNS45LTUuOEw5IDE2LjFsMy44IDMuN0wyNC4zIDguMmwyLjEgMi4yIi8+PC9zdmc+') center center;
			}
		}
	}
	&__instructions {
		@include body-font;
		margin: 0 0 1rem;
	}
	&__spacer {
		height: 2rem;
	}
	&__error-message {
		color: $error-color;
		margin-bottom: 3rem;
	}
	&__success-message {
		color: green;
		margin-top: 3rem;
	}
	&__field.has-error {
		.form__textInput {
			border-color: $error-color;
		}
	}
	.errors {
		margin: -2rem 0 3rem;
		li {
			color: $error-color;
		}
	}
	// allow fields to go side-by-side from medium
	@include breakpoint(medium) {
		&__row {
			display: flex;
		}
		&__field {
			flex-grow: 1;
			&:first-child:not(:last-child) {
				padding-right: 1.5rem;
			}
			&:last-child:not(:first-child) {
				padding-left: 1.5rem;
			}
		}
	}
}