.owl-dots {
	text-align: center;
	line-height: 1rem;
	margin-top: 6rem;
	.owl-dot {
		display: inline-block;
		padding: .75rem;
		margin: -.75rem 0; // cancels out space caused by vertical padding 
		span {
			display: block;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: $slate-grey-50;
		}
		&.active span, &:hover span {
			background: $purple;
		}
	}
}

// show carousels that have been disabled - based on http://stackoverflow.com/questions/28251644/disabling-owl-carousel-at-a-specific-viewport-width
.owl-carousel.off {
    display: block;
}

// equal height owl items – based on https://github.com/OwlCarousel2/OwlCarousel2/issues/378#issuecomment-314861156
.owl-stage {
	display: flex;
	flex-wrap: wrap;
}
.owl-item {
	display: flex;
	height: auto !important;
}