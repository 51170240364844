.notes-header {
	@extend %text-column;
	@extend %gutters;
	@include clearfix;
	.short-rule-heading {
		float: left;
	}
	.content-toggle {
		float: right;
	}	
}