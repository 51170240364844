.search-results {
	&__content {
		padding-bottom: 3rem;
	}
	&__entry {
		@extend %gutters;
		padding-bottom: 2rem;
		&:not(:first-child):before {
			content: '';
			display: block;
			border-top: 1px solid $slate-grey-50;
			padding-top: 2rem;
		}
		&__image {
			display: none;
		}
		&__section {
			@include h6-font;
			color: $peach;
			margin-bottom: 1rem;
		}
		&__date {
			@include h6-font;
			color: $cpp-grey;
			margin: -1rem 0 1rem;
		}
		&__title {
			@include bold-body-font;
			a {
				color: $text-color;
				&:hover {
					color: $purple;
				}
			}
		}
		&__teaser {
			@include body-font;
		}
	}
	@include breakpoint(600px) {
		&__content {
			padding-bottom: 6rem;
		}
		&__entry {
			@include clearfix;
			padding-bottom: 3rem;
			&:not(:first-child):before {
				padding-top: 3rem;
			}
			&__image {
				display: block;
				float: left;
				width: 22rem;
			}
			&__text {
				margin-left: 25rem;
			}
		}
	}
}