%tag {
	float: left;
	display: block;
	@include small-text-font;
	padding: 0.5rem 2rem;
	margin: 0 1rem 1rem 0;
	border-radius: 2rem;
	background: $light-peach;
	color: $text-color;
	transition: background-color .15s;
	cursor: pointer;
	&:hover {
		background-color: darken($light-peach, 5%);
	}
	@include breakpoint(medium) {			
		padding: 1rem 2.5rem;
		border-radius: 2.5rem;
	}
}

.tags {
	@include clearfix;
	&__tag {
		&__link {
			@extend %tag;
		}
		@include breakpoint(medium) {			
			.tags__tag--small &__link {
				padding: 0.5rem 2rem;
				border-radius: 2rem;
			}
		}
	}
}