.footer {
	@extend %section-padding;
	background: $rich-black;
	* {
		color: #fff;
	}
	a {
		text-decoration: underline;
	}
	&__logo {
		@extend %gutters;
		&__svg {
			width: 36rem;
			height: 12rem;
			margin: 0 auto 6rem;
			&--ign {
				height: 9rem;
				svg {
					path {
						fill: $ign_green;
					}
				}
			}
		}
	}
	&__about {
		@extend %gutters;
		margin-bottom: 5rem;
		p {
			@include small-text-font;
			&:not(:last-child) {
				margin-bottom: 3rem;
			}
		}

		// .awards-logo-frame {
		// 	height: 96px;
		// }

		.awards-logo {
			width: auto;
			max-width: 96px;
			max-height: 96px;
		}
	}
	&__hr {
		clear: both;
		border-top: 1px solid $cpp-grey-50;
		margin: 0 $gutter-half-width-small;
		@include breakpoint(medium) {
			margin: 0 $gutter-half-width-medium;
		}
		&--margin-bottom {
			margin-bottom: 6rem;
		}
	}
	&__contact {
		@extend %gutters;
		margin: 0 0 2rem 0;
		&__p {
			@include small-text-font;
			&:not(:last-child) {
				margin-bottom: 2rem;
			}
		}
	}
	&__share {
		@extend %gutters;
		@include clearfix;
		margin-bottom: 5rem;
		&__link {
			float: left;
			margin-right: 3rem;
		}
		&__text {
			display: none;
		}
		&__icon {
			width: 4rem;
			height: 4rem;
			svg {
				width: 100%;
			}
		}
	}
	&__small-print {
		@extend %gutters;
		margin: 5rem 0 0;
		&__p {
			@include small-text-font;
			&:last-child {
				margin-top: 2rem;
			}
		}
	}
	@include breakpoint(620px) {
		&__logo {
			float: left;
		}
		&__about {
			width: 50%;
			margin-left: 50%;
		}
		&__contact {
			width: 50%;
			float: right;
			margin-bottom: 5rem;
		}
		&__share {
			width: 50%;
			float: left;
		}
		&__small-print {
			position: relative;
			&__p {
				&:last-child {
					position: absolute;
					top: 0;
					right: $gutter-half-width-medium;
					margin-top: 0 !important;
				}
			}
		}
	}
	@include breakpoint(850px) {
		&__small-print {
			&__p:not(:last-child) {
				float: left;
				margin: 0 3rem 0 0;
			}
		}
	}
	@include breakpoint(large) {
		&__contact__p:first-child {
			float: left;
			&:after {
				content: '|';
				margin: 0 1rem;
			}
		}
	}
}