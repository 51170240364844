.default-header {
	position: relative;
	&__text {
		padding-top: 6rem;
		@extend %gutters;
		@extend %text-column;
		> :last-child {
			margin-bottom: 0 !important;
		}
		&__heading {
			@include h1-font;
			color: $text-color;
			&:not(:last-child) {
				@include bottom-short-rule;
			}
		}
		&__standfirst {
			@include h3-font;
			color: $cpp-grey;
			margin-bottom: 6rem;
		}
		&__date {
			@include bold-body-font;
		}
		&__author {
			a {
				@include link;
			}
		}
		.tags {
			margin-top: 3.5rem;
			margin-bottom: 3rem;
		}
		&__event-details {
			@include bold-body-font;
			margin-top: 2rem;
		}
	}
	// when off-white section follows a header, add a bit of space above it
	& + .section--cpp-grey-10 {
		margin-top: 7rem;
	}
	&--404 {
		padding-bottom: 20rem;
		.default-header__text__heading {
			color: $pink;
		}
	}
}