.section {
	@extend %section-padding;
	background: #fff;
	&--top-rule {
		padding-top: 0;
		.section-wrap:before {
			content: '';
			display: block;
			border-top: 1px solid $slate-grey-50;
			margin: 0 $gutter-half-width-small;
			@extend %section-padding;
			padding-bottom: 0;
			@include breakpoint(medium) {
				margin: 0 $gutter-half-width-medium;
			}
		}
	}
	&--cpp-grey {background: $cpp-grey}
	&--cpp-grey-10 {background: $cpp-grey-10}
	&--cpp-grey-10 + &--cpp-grey-10 {
		border-top: 1px solid $cpp-grey-50;
	}
	&--rich-black {background: $rich-black}
	&--pale-grey {background: $pale-grey}
	&--light-blue {background: $light-blue}
	&--light-peach {background: $light-peach}
	&--light-peach-40 {background: $light-peach-40}
	&--pink {background: $pink}
	&--green {background: $green}
	&--gold {background: $gold}
	&--purple {background: $purple}
	&--slate-grey {background: $slate-grey}
	&--peach {background: $peach}
	&--blue {background: $blue}
	&--light-blue-20 {background: $light-blue-20}
	&--slate-grey-50 {background: $slate-grey-50}
	&--nav-block + &--body {
		// close gap when body comes after nav block
		padding-top: 0;
	}
	&--ign {
		background: $ign_richBlack;
	}
	&__heading {
		@include h3-font;
		margin-bottom: 4rem;
		color: $cpp-grey;
		&--gtr {
			@extend %gutters;
		}
		&--text-col {
			@extend %text-column;
		}
		&--white {
			color: #fff;
		}
		&--black {
			color: $text-color;
		}
	}
	&__read-more {
		clear: both;
		@extend %gutters;
		margin-top: 6rem;
		text-align: center;
		&:before {
			content: '';
			display: block;
			border-top: 1px solid $cpp-grey-50;
			padding-bottom: 6rem;
		}
	}
	&__headerCTA {
		padding-top: 3.4rem;
    padding-bottom: 3.4rem;
		.section-wrap {
			.cta-wrap {
				padding-left: 12px;
				padding-right: 12px;
			}
		}
		&--heading {
			font-family: InteloAlt,arial,sans-serif;
	    font-size: 3.4rem;
	    line-height: 4rem;
	    font-weight: 700;
	    margin-bottom: 1.6rem;
		}
		@include breakpoint(medium) {
			.section-wrap {
				.cta-wrap {
					padding-left: 24px;
					padding-right: 24px;
				}
			}
		}
		@include breakpoint(large) {
			.section-wrap {
				.cta-wrap {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}
			&--heading {
				margin-bottom: 0;
			}
		}
	}
}
