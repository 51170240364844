.main-content--ign {
	.default-header {
		position: relative;
		&__text {
			&__heading {
				color: $ign_richBlack;
			}
			&__standfirst {
				color: $ign_richBlack;
			}
			&__author {
				a {
					@include linkIgn;
				}
			}
		}
		&--404 {
			.default-header__text__heading {
				color: $pink;
			}
		}
	}
	.landing-page-header {
		&__standfirst {
			@extend %gutters;
			color: $ign_richBlack;
			@include top-short-rule;
			max-width: $max-text-width;
			@include body-font;
			p {
				color: $ign_richBlack;
				@include body-font;
				margin-bottom: 2rem;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					@include linkIgn;
				}
			}
		}
	}
}
