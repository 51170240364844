.stat-with-body {
	@extend %image-margins;
	margin-left: -$screen-margin-small;
	margin-right: -$screen-margin-small;
	&__content {
		padding-left: $screen-margin-small;
		padding-right: $screen-margin-small;
		padding-top: 6rem;
		padding-bottom: 6rem;
		background: $rich-black;
	}
	&__text {
		@extend %gutters;
	}
	&__text-1, &__text-2 {
		@include h5-font;
		color: #fff;
	}
	&__text-1 {
		display: block;
	}
	&__text-2 {
		display: inline-block;
		position: relative;
		top: 2rem;
	}
	&__figure {
		@include stat-font;
		color: $pink;
		padding-right: 1rem;
		position: relative;
		top: 2rem;
	}
	&__icon {
		@extend %gutters;
		margin-top: 7rem;
		&__svg {
			width: 20rem;
		}
	}
	@include breakpoint(medium) {
		margin-left: -$screen-margin-medium;
		margin-right: -$screen-margin-medium;
		&__content {
			padding-left: $screen-margin-medium;
			padding-right: $screen-margin-medium;
			@include clearfix;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
		}
		&__text {
			width: 75%;
			float: right;
		}
		&__icon {
			width: 25%;
			float: left;
			margin-top: 0;
			svg {
				width: 100%;
			}
		}
	}
	@include breakpoint(large) {
		width: 66.666666%;
		margin-left: auto;
		margin-right: auto;
		&__content {
			margin-left: -$gutter-half-width-medium;
			margin-right: -$gutter-half-width-medium;
		}
	}
}