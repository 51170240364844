.main-content--ign {
	.standout-text {
		&__text {
			p {
				&:before {
					background: $ign_green;
				}
			}
		}
	}
}
