.content-toggle {
	@include small-text-font;
	color: $purple;
	text-decoration: underline;
	cursor: pointer;
	&:focus {
		outline: none;
	}
	&:after {
		display: inline-block;
		content: '';
		// arrow
		border-style: solid;
		border-width: 10px 8px 0 8px;
		border-color: #75529d transparent transparent transparent;
		margin-left: 1rem;
		position: relative;
		top: -.25rem;
		transition: transform .2s;
	}
	&:hover {
		color: $text-color;
		&:after {
			border-color: $text-color transparent transparent transparent;
		}
	}
	&--active:after {
		transform: rotate(180deg);
	}
	&--blue {
		color: $blue;
		&:after {
			border-color: $blue transparent transparent transparent;
		}
	}
}
.content-toggled {
	overflow: hidden;
	position: relative;
	will-change: height;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 6rem;
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.5) 20%, rgba(255,255,255,1) 100%);
		.section--cpp-grey-10 & {
			background: linear-gradient(to bottom, rgba(240,239,241,0) 0%, rgba(240,239,241,.5) 20%, rgba(240,239,241,1) 100%);
		}
		.section--light-blue & {
			background: linear-gradient(to bottom, rgba(188,213,240,0) 0%, rgba(188,213,240,.5) 20%, rgba(188,213,240,1) 100%);
		}
		.section--light-blue-20 & {
			background: linear-gradient(to bottom, rgba(242,246,252,0) 0%, rgba(242,246,252,.5) 20%, rgba(242,246,252,1) 100%);
		}
		.section--ign_lightBlue_30 & {
			background: linear-gradient(to bottom, rgba(235,243,250,0) 0%, rgba(235,243,250,.5) 20%, rgba(235,243,250,1) 100%);
		}
	}
}
