* {
	box-sizing: border-box;
}

img, svg {
	display: block;
	max-width: 100%;
	height: auto;
}

html, body {
	height: 100%;
}

.section-wrap {
	@extend %screen-margins;
	max-width: $max-screen-width;
	margin: 0 auto;
	@include clearfix;
}

/******************************************************************************************
GRID
******************************************************************************************/

%gutters {
	padding-left: $gutter-half-width-small;
	padding-right: $gutter-half-width-small;
	@include breakpoint(medium) {
		padding-left: $gutter-half-width-medium;
		padding-right: $gutter-half-width-medium;
	}
}
%left-gutter {
	padding-left: $gutter-half-width-small;
	@include breakpoint(medium) {
		padding-left: $gutter-half-width-medium;
	}
}
%right-gutter {
	padding-right: $gutter-half-width-small;
	@include breakpoint(medium) {
		padding-right: $gutter-half-width-medium;
	}
}
%negative-gutters {
	margin-left: -$gutter-half-width-small;
	margin-right: -$gutter-half-width-small;
	@include breakpoint(medium) {
		margin-left: -$gutter-half-width-medium;
		margin-right: -$gutter-half-width-medium;
	}
}
%screen-margins {
	padding-left: $screen-margin-small; padding-right: $screen-margin-small;
	@include breakpoint(medium) {padding-left: $screen-margin-medium; padding-right: $screen-margin-medium}
}
%screen-margins-with-gutters {
	padding-left: $screen-margin-small-with-gutter; padding-right: $screen-margin-small-with-gutter;
	@include breakpoint(medium) {padding-left: $screen-margin-medium-with-gutter; padding-right: $screen-margin-medium-with-gutter}
}
%negative-screen-margins {
	margin-left: -$screen-margin-small; margin-right: -$screen-margin-small;
	@include breakpoint(medium) {margin-left: -$screen-margin-medium; margin-right: -$screen-margin-medium}
}
%negative-screen-with-gutters {
	margin-left: -$screen-margin-small-with-gutter; margin-right: -$screen-margin-small-with-gutter;
	@include breakpoint(medium) {margin-left: -$screen-margin-medium-with-gutter; margin-right: -$screen-margin-medium-with-gutter}
}

/******************************************************************************************
TEXT/IMAGE COLUMNS
******************************************************************************************/

%text-column {
	max-width: $max-text-width;
	margin-left: auto;
	margin-right: auto;
	@include breakpoint(medium) {
	}
	@include breakpoint(large) {
		width: 66.666666%;
	}
}

// %8-column {
// 	max-width: none;
// 	margin-left: auto;
// 	margin-right: auto;
// 	@include breakpoint(large) {
// 		width: 66.666666%;
// 	}
// }

%section-padding {
	padding-top: 6rem;
	padding-bottom: 7rem;
}

%image-margins {
	margin-top: 6rem;
	margin-bottom: 6rem;
}
