.entry-card {
	@extend %gutters;
	position: relative;
	margin-bottom: 6rem;
	&:before {
		content: '';
		position: absolute;
		left: -1px;
		top: 0;
		bottom: 0;
		border-left: 1px solid $cpp-grey-50;
		.entry-card-list--light-blue & {
			border-left: 1px solid #fff;
		}
	}
	.owl-carousel & {
		margin-bottom: .5rem; // just a bit of space so we don't cut off the button shadows
		width: 100%;
		height: 100%;
	}
	.owl-carousel & {
		// hide side rules
		&:before {
			content: none;
		}
	}
	.entry-card-list--owl-rules & {
		// show side rules again if asked to by css class
		&:before {
			content: '';
		}
	}
	&__img {
		margin-bottom: 2rem;
	}
	&__date {
		@include h6-font;
		margin-bottom: 1rem;
	}
	// tighten up space if date comes straight after image
	a:first-child + &__date {
		margin-top: -1rem;
	}
	&__section {
		@include h6-font;
		margin-bottom: 1rem;
	}
	&__heading {
		@include h5-font;
		margin-bottom: 1rem;
		a {
			color: $text-color;
			&:hover {
				color: $purple;
			}
		}
	}
	&__subheading {
		@include h5-font;
		margin-bottom: 3rem;
		color: $cpp-grey;
	}
	&__excerpt, &__author {
		@include small-text-font;
		margin-bottom: 1rem;
		a {
			@include link;
		}
	}
	.tags {
		margin-top: 3rem;
	}
	.btn {
		margin-top: 3rem;
	}
	@include breakpoint(medium) {
		width: 50%;
		float: left;
		&:nth-of-type(2n+1) {
			clear: left;
		}
	}
	@include breakpoint(large) {
		width: 33.333333%;
		&:nth-of-type(2n+1) {
			clear: none;
		}
		&:nth-of-type(3n+1) {
			clear: left;
		}
	}
}