.main-content--ign {
  .entry-card {
    &__heading {
  		a {
  			color: $ign_richBlack;
  			&:hover {
  				color: $ign_green;
  			}
  		}
  	}
    &__author {
      a {
  			@include linkIgn;
  		}
    }
    &-list {
      &--ign_green_20 {
        &:before, &:after {
          border-color: $ign_green_20;
        }
      }
      &__no-results {
        a {
          @include linkIgn;
        }
      }
      .entry-card {
        // &:before {
        //   border-color: $white;
        // }
        &__past {
          &:before {
            border-color: $white;
          }
          .entry-card {
            &__subheading {
              color: $ign_richBlack_50;
            }
          }
        }
      }
    }
  }
  .owl-dots {
    .owl-dot {
      &.active span, &:hover span {
  			background: $ign_green;
  		}
    }
  }
}
