.main-content--ign {
	.hero-header {
		&__text {
			&__heading {
				color: $ign_richBlack;
				@include bottom-short-rule($color: $ign_richBlack);
			}
			&__subheading {
				color: $ign_richBlack;
			}
			&__author {
				a {
					@include linkIgn;
				}
			}
		}
		&--home {
      border-top: none;
			background-color: $ign_green;
			.hero-header__home-tagline {
				color: $ign_richBlack;
				@include breakpoint(large) {
					// font-size: 10rem;
					// line-height: 10.75rem;
					max-width: 9em;
					padding-bottom: 10rem;
					padding-top: 10rem;
				}
			}
			.hero-header__bg {
				background-color: $ign_green;
				@include breakpoint(large) {
					position: absolute;
				}
			}
			.hero-header__img {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
				margin-top: -10vw;
				margin-bottom: -10vw;
				svg {
					width: auto;
					height: 100%;
					margin-right: 12px;
					max-width: 320px;
					max-height: 320px;
					animation: rotating 80s linear infinite;
					path {
						fill: $white;
					}
				}
				@include breakpoint(medium) {
					svg {
						margin-right: 48px;
					}
				}
				@include breakpoint(large) {
					position: absolute;
					top: 5%;
					right: 0;
					bottom: 5%;
					left: 0;
					width: auto;
					height: 90%;
					max-width: 1240px;
    			max-height: 372px;
					margin-top: 0;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 0;
				}
			}
		}
		@include breakpoint(large) {
			&__text {
				margin-bottom: 6rem;
			}
			&--ignEvents {
				.hero-header {
					&__text {
						margin-bottom: 12rem;
					}
				}
			}
		}
	}
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
