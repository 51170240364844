/******************************************************************************************
COLORS
******************************************************************************************/

$cpp-grey: rgb(101,91,114);
$rich-black: rgb(35,20,44);
$pale-grey: rgba(101,91,114,0.1);
$light-blue: rgb(188,213,240);
$light-peach: rgb(250,193,138);
$light-peach-40: rgba($light-peach, .4);
$pink: rgb(202,80,142);
$green: rgb(63,180,152);
$gold: rgb(182,169,118);
$purple: rgb(117,82,157);
$slate-grey: rgb(82,98,120);
$peach: rgb(240,130,98);
$blue: rgb(99,153,175);
$light-blue-20: #f2f6fc;
$slate-grey-50: #a9b0bb;
$cpp-grey-75: #8b8395;
$cpp-grey-50: #b2adb8;
$cpp-grey-10: #f0eff1;

$text-color: #000000;
$link-color: $purple;

$error-color: red;

/******************************************************************************************
IGN COLORS
******************************************************************************************/
$white: #ffffff;
$ign_richBlack: #003728;
$ign_richBlack_75: rgba($ign_richBlack, .75);
$ign_richBlack_50: rgba($ign_richBlack, .5);
$ign_green: #3fb498;
$ign_green_20: #daf0ea;
$ign_linkBlue: #017bae;
$ign_linkBlue_50: #80bdd6;
$ign_lightBlue: #bcd5f0;
$ign_lightBlue_30: rgba($ign_lightBlue, .3);
$ign_lightBlue_30_solid: #ebf3fa;
$ign_slateGrey: #526278;

/******************************************************************************************
LAYOUT VARIABLES
******************************************************************************************/

// SCREEN SIZES

$max-screen-width: 1240px;
$smallish-screen: 480px;
$medium-screen: 768px;
$large-screen: 992px;
$huge-screen: 1200px;

$max-text-width: 800px;

$nav-breakpoint: 1250px;

// GUTTERS

$gutter-half-width-small: 12px;
$gutter-half-width-medium: 24px;
$screen-margin-small: 2px;
$screen-margin-medium: 24px;
$screen-margin-small-with-gutter: $screen-margin-small + $gutter-half-width-small;
$screen-margin-medium-with-gutter: $screen-margin-medium + $gutter-half-width-medium;
