.nav-block {
	&__standfirst {
		@extend %gutters;
		@include h5-font;
		color: $cpp-grey;
		margin-bottom: 6rem;
		max-width: $max-text-width;
	}
	&__item {
		@extend %gutters;
		margin-bottom: 8rem;
		&__icon {
			width: 24rem * 1.2105;
			height: 24rem;
			margin-bottom: 2rem;
			&--logo {
				background-color: $white;
				display: flex;
				justify-items: center;
				align-items: center;
				padding: 4rem;
				img {
					max-height: 100%;
					object-fit: contain;
				}
			}
		}
		&__heading {
			@include bold-body-font;
			margin-bottom: 1rem;
		}
		&__text {
			margin-bottom: 3rem;
		}
		&:last-child {
			margin-bottom: 10rem;
		}
	}
	@include breakpoint(600px) {
		&__standfirst {
			width:83.333333%;
		}
		&__items {
			display: flex;
			flex-wrap: wrap;
		}
		&__item {
			width: 50%;
			float: left;
			display: flex;
			flex-direction: column;
			&__icon {
				margin-bottom: 5rem;
			}
			&__btn {
				margin-top: auto;
			}
			&:last-child {
				margin-bottom: 8rem;
			}
			// &__heading, &__text {
			// 	width: 83.333333%;
			// 	padding-right: $gutter-half-width-medium;
			// }
			// &--fullwidth {
			// 	width: 100%;
			// }
		}
	}
	@include breakpoint(large) {
		&__standfirst {
			width:66.666666%;
		}
		&__item {
			&__icon {
				width: 30rem * 1.2105;
				height: 30rem;
			}
			// &__heading, &__text {
			// 	width: 66.666666%;
			// }
		}
	}
}

.nav-block-logos-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2rem;
   .logo-wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0;
      .logo-frame {
         &--out {
            position: relative;
            width: 100%;
            padding-top: 85%;
         }
         &--in {
            position: absolute;
            top: 0;
            right: 1rem;
            bottom: 1rem;
            left: 0;
						padding: 1rem;
						background-color: $white;
            display: flex;
            justify-items: center;
            align-items: center;
         }
      }
      .logo {
         max-height: 100%;
         object-fit: contain;
      }
   }
	@include breakpoint(medium) {
      .logo-wrapper {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
   }
   @include breakpoint(large) {
      .logo-wrapper {
        flex: 0 0 20%;
        max-width: 20%;
      }
   }
}