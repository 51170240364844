.people-heading {
	@extend %text-column;
	@include h3-font;
	margin-bottom: 5rem;
	&__wrap {
		@extend %gutters;
		@include breakpoint(600px) {
			margin-left: 25%;
		}
	}
}