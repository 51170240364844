.hero-header {
	position: relative;
	overflow: hidden;
	&__text {
		padding-top: 6rem;
		padding-bottom: 6rem;
		@extend %gutters;
		@extend %text-column;
		max-width: none;
		&__heading {
			@include h1-font;
			color: $text-color;
			@include bottom-short-rule;
		}
		&__subheading {
			@include h3-font;
			color: $cpp-grey;
			margin-bottom: 6rem;
		}
		&__date {
			@include bold-body-font;
		}
		&__author {
			margin-bottom: 3.5rem;
			a {
				@include link;
			}
		}
		&__event-details {
			@include bold-body-font;
			margin-top: 2rem;
		}
	}
	&__bg {
		height: 50vw;
		background-size: cover !important;			
	}
	&__read-time {
		@include small-text-font;
		padding: 1.5rem 6rem;
		background: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
	&--home {
		background: $rich-black;
		border-top: 1px solid $cpp-grey;
		.section-wrap {
			display: flex;
			flex-direction: column;
			@media screen and (min-width: 600px) {
				flex-direction: row;
				align-items: center;
			}
		}
		.hero-header__home-tagline {
			@extend %gutters;
			width: 100%;
			max-width: 320px;
			color: #fff;
			z-index: 1;
			position: relative;
			font-family: "InteloAlt", arial, sans-serif;
			font-weight: bold;
			font-size: 36.75px;
			line-height: 1.14em;
			margin-top: 35px;
			@media screen and (min-width: 600px) {
				max-width: 50%;
				margin-top: 0;
				padding-right: 0;
			}
			// min-width: 768px
			@include breakpoint(medium) {
				font-size: 5.25rem;			
			}
			// min-width: 992px
			@include breakpoint(large) {
				font-size: 7.25rem;	
				line-height: 1.1em;			
			}
			// min-width: 1250px
			@include breakpoint($nav-breakpoint) {
				font-size: 9rem;
				line-height: 1em;
			}
		}
		.hero-header__img {
			@extend %gutters;
			width: 100%;
			margin-top: 2rem;
			display: flex;
			justify-content: flex-end;
			svg {
				width: 70%;
				max-width: 567px;
				height: auto;
			}
			@media screen and (min-width: 600px) {
				width: 50%;
				margin-top: 5rem;
				padding-left: 0;
				svg {
					width: 100%;
				}	
			}
		}
	}
	@include breakpoint(large) {
		&__text {
			// width: calc(#{percentage(4/6)} + #{($gutter-half-width-medium * 2)});
			width: calc(66.666666% + (#{$gutter-half-width-medium} * 2));
			padding-left: calc(#{$gutter-half-width-medium} * 2);
			padding-right: calc(#{$gutter-half-width-medium} * 2);
			background: #fff;
			position: relative;
			z-index: 1;
			margin-bottom: 27rem;
			display: flex;
			// move tags to top of text panel
			flex-direction: column;
			.hero-header--publications & :not(.tags) {
				order: 1;
			}
			.hero-header--publications & .tags {
				margin: 0 0 6rem;
			}
			.hero-header--publications &__author {
				margin-bottom: 0;
			}
		}
		&__bg {
			position: absolute;
			top: -11rem;
			left: 0;
			right: 0;
			bottom: 0;
			height: auto;
			will-change: translate;
		}
		&__read-time {
			position: relative;
			float: left;
			margin-left: calc(16.666666% - #{$gutter-half-width-medium});
		}
	}
}