.read-report {
	background: $cpp-grey-75;
	overflow: hidden;
	&__content {
		@extend %gutters;
		@extend %text-column;
		padding-top: 6rem;
	}
	.btn {
		margin: 0 1rem 2.5rem 0;
	}
	&__img {
		width: 29rem;
		transform: rotate(-25deg);
		margin: 5rem 0 -6rem 9rem;
		box-shadow: -1rem 1rem 1.25rem rgba(0, 0, 0, .1);
	}
	@include breakpoint(medium) {
		&__content {
			padding-bottom: 11rem;
			position: relative;
		}
		.section__heading {
			width: 54rem;
		}
		&__btns {
			width: 54rem;
		}
		&__img {
			position: absolute;
			top: 50%;
			left: 64rem;
			transform-origin: 50% 50%;
			transform: translateY(-50%) rotate(-25deg);
			margin: 0;
		}
	}
}