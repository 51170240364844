@mixin linkIgn {
	color: $ign_linkBlue;
	background-image: linear-gradient($ign_linkBlue 50%, rgba(0,0,0,0) 50%);
	background-repeat: repeat-x;
	background-size: 2px 2px;
	background-position: 0 1.15em;
	&:hover {
		color: $ign_green;
		background-image: linear-gradient($ign_green 50%, rgba(0,0,0,0) 50%);
	}
}

.main-content--ign {
  color: $ign_richBlack;
  p, li {
  	color: $ign_richBlack;
  }
  ul, ol {
  	color: $ign_richBlack;
  }
  .wysiwyg {
    ul {
      & > li {
        &:before {
          background-color: $ign_green;
        }
      }
    }
    a {
      @include linkIgn;
    }
		strong {
			@include bold-body-font;
		}
  }
  .newsletter {
    &__heading {
  		color: $ign_richBlack;
  	}
  }
	.landing-page-header {
		&__standfirst {
			@include body-font;
		}
	}
	.cta__heading {
		color: $ign_richBlack;
	}
}
