.main-content--ign {
	.stat {
		&__figure {
			color: $ign_green;
		}
		&__text {
			color: $ign_green;
		}
	}
}
