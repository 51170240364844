.hero-header-image {
   margin-bottom: -5rem;
   .hero-header__bg {
      position: relative;
      height: 0;
      padding-top: 50%;
   }
   .hero-header__text {
      padding-bottom: 0;
      margin-bottom: 0;
      max-width: 800px;
      &--dates {
         @include bold-body-font;
         margin-top: 3rem;
      }
   }
   @include breakpoint(large) {
      position: relative;
      padding-top: 40%;
      overflow: hidden;
      .hero-header__bg {
         position: absolute;
         height: 50vw;
         padding-top: 0;
      }
      .hero-header__text {
         padding-bottom: 0;
         margin-top: -5rem;
         max-width: none;
      }
   }
}