/******************************************************************************************
STYLE MIXINS
*******************************************************************************************/

@mixin top-short-rule($color: $slate-grey-50) {
	&:before {
		content: '';
		display: block;
		border-top: 1px solid $color;
		margin: 3rem 0 3rem;
		width: 12rem;
	}
}
@mixin bottom-short-rule($color: $slate-grey-50) {
	&:after {
		content: '';
		display: block;
		border-top: 1px solid $color;
		margin: 3rem 0 3rem;
		width: 12rem;
	}
}


/******************************************************************************************
BREAKPOINTS
******************************************************************************************/

@mixin breakpoint($point) {
	@if $point == huge {
		@media screen and (min-width: $huge-screen) { @content; }
	}
	@else if $point == large {
		@media screen and (min-width: $large-screen)	{ @content; }
	}
	@else if $point == medium {
		@media screen and (min-width: $medium-screen)	{ @content; }
	}
	@else if $point == smallish {
		@media screen and (min-width: $smallish-screen)	{ @content; }
	}
	@else {
		@media screen and (min-width: $point) { @content; }
	}
}
@mixin below-breakpoint($point) {
	@if $point == huge {
		@media screen and (max-width: $huge-screen - 1) { @content; }
	}
	@else if $point == large {
		@media screen and (max-width: $large-screen - 1)	{ @content; }
	}
	@else if $point == medium {
		@media screen and (max-width: $medium-screen - 1)	{ @content; }
	}
	@else if $point == smallish {
		@media screen and (max-width: $smallish-screen - 1)	{ @content; }
	}
}

/******************************************************************************************
HELPER MIXINS
******************************************************************************************/

@mixin box {
	box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; *behavior: url(/js/third-party/boxsizing.htc);	
}

@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	} 
	&:after {
		clear: both;
	}
	zoom: 1;
}

/******************************************************************************************
100vh FOR iOS (SETS HEIGHT EXPLICITLY FOR IOS, AS VH IS BUGGY IN SAFARI)
******************************************************************************************/

// these are whatever is the deepest chrome for each device in Safari or Chrome
$iphone-chrome-height-portait: 108px;
$iphone-chrome-height-landscape: 43px;
$ipad-chrome-height-portait: 116px;
$ipad-chrome-height-landscape: 116px;

@mixin vh100-ios($selector:"height") {
	#{$selector}: 100vh;
	// ----------- iPhone 4 and 4S -----------
	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
		#{$selector}: 480px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
		#{$selector}: 320px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 5 and 5S -----------
	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
		#{$selector}: 568px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
		#{$selector}: 320px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 6 -----------
	@media only screen and (min-device-width: 375px) and (max-device-width: 667px)	and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) { 
		#{$selector}: 667px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 375px) and (max-device-width: 667px)	and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { 
		#{$selector}: 375px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 6+ -----------
	@media only screen and (min-device-width: 414px) and (max-device-width: 736px)	and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) { 
		#{$selector}: 736px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 414px) and (max-device-width: 736px)	and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
		#{$selector}: 414px - $iphone-chrome-height-landscape;
	}
	/* ----------- iPad mini, 1 and 2 ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
		#{$selector}: 1024px - $ipad-chrome-height-portait;
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		#{$selector}: 768px - $ipad-chrome-height-landscape;
	}
	/* ----------- iPad 3 and 4 ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
		#{$selector}: 1024px - $ipad-chrome-height-portait;
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
		#{$selector}: 768px - $ipad-chrome-height-landscape;
	}
	// slightly arbritrary fallback for browsers that don't support vh units
	.no-cssvhunit & {
		#{$selector}: 800px;
	}
}