.btn {
	display: inline-block;
	border-radius: 0;
	-webkit-appearance: none;
	cursor: pointer;
	@include h4-font;
	color: #fff;
	padding: 1rem 3rem;
	background: $purple;
	border-radius: 3rem;
	box-shadow: 0 .5rem 0 #000;
	position: relative;
	top: 0;
	transition: all .08s;
	&:focus {
		outline: none;
	}
	&:hover {
		background-color: darken($purple, 10%);
	}
	&:active {
		position: relative;
		top: .5rem;
		box-shadow: 0 0 0 #000;
	}
	&:disabled, &.disabled {
		opacity: .5;
	}
	&--yellow {
		background-color: $light-peach;
		color: $text-color;
		&:hover {
			background-color: darken($light-peach, 10%);
		}
	}
	&--ign_green {
		color: $text-color;
		background-color: $ign_green;
		&:hover {
			background-color: lighten($ign_green, 10%);
		}
	}
}
