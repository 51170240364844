#speakersBlock {
    border-top: 1px solid $cpp-grey-50;
}

.speakers-heading__wrap {
    padding: 0 2rem;

    @include breakpoint(768px) {
        padding: 0 3rem;
	}
}