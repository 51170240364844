.cookies {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background: $rich-black;
	z-index: 99999;
	padding: 2rem 0;
	border-top: 1px solid rgba($color: #fff, $alpha: .2);
	transition: transform .3s;
	&__p {
		@extend %gutters;
		color: #fff;
		margin-bottom: 2rem;
		@include h4-font;
		a {
			@include link-white;
		}
	}
	&__btn {
		@extend %gutters;
		margin-left: auto;
		.btn {
			box-shadow: none;
		}
	}
	&--accepted {
		transform: translateY(100%);
	}
	@include breakpoint(500px) {
		&__p {
			margin-bottom: 0;
		}
		.section-wrap {
			display: flex;
			align-items: center;
		}
	}
}