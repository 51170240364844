.image {
	@extend %gutters;
	@extend %image-margins;
	&__img {
		display: block;
		width: 100%; // Safari doesn't load in larger responsive images when you widen your browser, so this at least makes sure the layout doesn't get messed up
	}
	&--medium {
		@extend %text-column;
	}

	&--small {
		width: 43.3333%;
		max-width: 520px;
		margin: 0 auto;
		margin-top: 6rem;
		margin-bottom: 6rem;
	}
}