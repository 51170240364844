$hamburger-btn-width: 32px;
$hamburger-btn-height: 32px;
$hamburger-btn-line-width: 5px;
$hamburger-btn-padding-v: 0;
$hamburger-btn-padding-h: 0;

$header-height-small: 11rem;
$header-height-medium: 13rem;
$header-height-medium-home: 16rem;

body {
	padding-top: $header-height-small;
	@include breakpoint($nav-breakpoint) {
		padding-top: $header-height-medium;
		&.body--home {
			padding-top: $header-height-medium-home;
			background: $rich-black;
		}
	}
}

.site-header {

	/******************************************************************************************
	Mobile nav
	*******************************************************************************************/

	background: $rich-black;

	&__logo {
		@extend %left-gutter;
		padding-top: 2rem;
		padding-bottom: 2rem;
		float: left;
		&__text {
			display: none;
		}
		svg {
			width: 21rem;
			height: 7rem;
		}
	}

	// hamburger menu

	&__nav-btn {
		@extend %gutters;
		cursor: pointer;
		right: $screen-margin-small-with-gutter;
		background: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'); // this is a transparent gif – makes whole nav btn clickable in IE9, rather than just the lines
		float: right;
		margin-top: calc(5.5rem - 16px);
		&__text {
			display: none;
		}
		&__lines {
			width: $hamburger-btn-width;
			height: $hamburger-btn-height;
			padding: $hamburger-btn-padding-v $hamburger-btn-padding-h;
			position: relative;
			float: right;
		}
		&__l {
			width: $hamburger-btn-width;
			height: $hamburger-btn-line-width;
			background: #fff;
			position: absolute;
			left: $hamburger-btn-padding-h;
			border-radius: 2.5px;
			opacity: 1;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
			&--1 {
				top: 0;
			}
			&--2, &--3 {
				top: 13.5px;
			}
			&--4 {
				top: 27px;
			}
		}
		&--is-active {
			// turn hamburger into a cross
			.site-header__nav-btn__l--1 {
				top: 13.5px;
				width: 0%;
				left: 50%;
			}
			.site-header__nav-btn__l--2 {
				transform: rotate(45deg);
			}
			.site-header__nav-btn__l--3 {
				transform: rotate(-45deg);
			}
			.site-header__nav-btn__l--4 {
				top: 13.5px;
				width: 0%;
				left: 50%;
			}
		}
	}

	// nav
	&__nav {
		@extend %gutters;
		clear: both;
		.JS & {
			display: none;
		}
		&__item {
			@include small-text-font;
		}
		&__link {
			display: block;
			color: #fff;
			padding-top: 2rem;
			padding-bottom: 2rem;
			border-top: 1px solid $slate-grey;
			&--selected, &:hover {
				color: $peach;
			}
		}
		&__share {
			display: block;
			@include clearfix;
			border-top: 1px solid $slate-grey;
			padding-top: 2.5rem;
			padding-bottom: 2.5rem;
			&__link {
				float: left;
				margin-right: 3rem;
			}
			&__text {
				display: none;
			}
			&__icon {
				width: 3rem;
				height: 3rem;
				path {
					fill: $slate-grey-50 !important;
				}
				&:hover path {
					fill: #fff !important;
				}
			}
		}
		&__search-btn {
			display: none;
		}
		&__search {
			background: $cpp-grey-10;
			@extend %negative-screen-with-gutters;
			border-bottom: 1px solid darken($cpp-grey-10, 10%);
			&__label {
				display: none;
			}
			&__input {
				border: none;
				background: transparent;
				@include h4-font;
				@extend %screen-margins-with-gutters;
				padding: 2rem 0 2rem 7rem;
				color: $slate-grey;
				display: block;
				width: 100%;
				background: url(data:image/svg+xml,%3Csvg%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cg%20id%3D%22search%22%20fill%3D%22%23526278%22%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M7.725%2C0%20C3.459%2C0%200%2C3.459%200%2C7.725%20C0%2C11.991%203.459%2C15.45%207.725%2C15.45%20C11.991%2C15.45%2015.45%2C11.991%2015.45%2C7.725%20C15.45%2C3.459%2011.991%2C0%207.725%2C0%20M7.725%2C12.333%20C5.18%2C12.333%203.117%2C10.27%203.117%2C7.725%20C3.117%2C5.18%205.18%2C3.117%207.725%2C3.117%20C10.27%2C3.117%2012.333%2C5.18%2012.333%2C7.725%20C12.333%2C10.27%2010.27%2C12.333%207.725%2C12.333%22%20id%3D%22Fill-1%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3Cpolygon%20id%3D%22Fill-3%22%20points%3D%2213.4514%2016.4445%2021.0064%2023.9995%2024.0004%2021.0065%2016.4434%2013.4515%2013.1874%2013.1875%22%3E%3C%2Fpolygon%3E%0A%20%20%20%20%20%20%20%20%3C%2Fg%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E) $screen-margin-small-with-gutter center no-repeat;
				&:focus {
					outline: none;
				}
				@include breakpoint(medium) {
					padding-left: 10.5rem;
					background-position: $screen-margin-medium-with-gutter center;
				}
			}
			&__submit, &__close {
				display: none;
			}
		}
	}

	/******************************************************************************************
	DESKTOP NAV
	*******************************************************************************************/

	@include breakpoint($nav-breakpoint) {
		// large logo when at top of page
		.section-wrap {
			display: flex;
			align-items: center;
		}
		&__logo {
			padding-top: 3rem;
			padding-bottom: 3rem;
			transition: padding .15s;
			.body--home &, .site-header--is-fixed &, .site-header--is-hidden & {
				padding-top: 2rem;
				padding-bottom: 2rem;
			}
			svg {
				width: 21rem;
				height: 7rem;
				.body--home & {
					height: 12rem;
					transition: height .15s, width .15s;
				}
				// smaller logo when popping in mid-page
				.site-header--is-fixed &, .site-header--is-hidden & {
					width: 21rem;
					height: 7rem;
				}
			}
		}
		&__nav-btn {
			display: none;
		}
		&__nav {
			margin-left: auto;
			padding-left: 0;
			display: flex !important;
			align-items: center;
			float: right;
			clear: none;
			text-align: right;
			position: relative;
			max-width: 1000px;
			&__item {
				display: inline-block;
				margin-left: 2rem;
			}
			&__links {
				float: left;
				margin-right: 2rem;
			}
			&__link {
				border-top: none;
				padding: 0;
			}
			&__share {
				display: inline-block;
				border-top: none;
				//margin-right: 1rem;
				padding-top: .5rem;
				padding-bottom: 0;
				margin-right: -4px;
				margin-bottom: -8px;
				&__network {
					float: left;
				}
				&__link {
					margin-left: 2rem;
					margin-right: 0;
				}
			}
			&__search-btn {
				display: block;
				float: right;
				min-width: 4rem;
				padding: .5rem;
				margin-left: 1.5rem;
				cursor: pointer;
				&:hover path {
					fill: $peach;
				}
				&:focus {
					outline: none;
				}
			}
			&__search {
				opacity: 0;
				visibility: hidden;
				transition: opacity .2s, visibility .2s;
				background: none;
				padding: 0;
				margin: 0;
				border: none;
				position: absolute;
				top: 50%;
				margin-top: -3.5rem;
				right: 2.5rem;
				left: 0;
				&--is-open {
					opacity: 1;
					visibility: visible;
				}
				&__input {
					background-color: #fff;
					background-position: 2rem center;
					padding: 1.5rem 1rem 1.5rem 6.5rem;
					border-radius: 4rem;
				}
				&__close {
					display: block;
					position: absolute;
					top: 2rem;
					right: 1.5rem;
					cursor: pointer;
					&:hover path {
						stroke: $slate-grey;
					}
				}
			}
		}
	}

	/******************************************************************************************
	Sticky header
	*******************************************************************************************/

	.JS & {
		z-index: 1000;
		position: absolute;
		left: 0;
		right: 0;
		top: $header-height-small;
		margin-top: -$header-height-small; // slightly odd combo of top and margin-top here is so that transition kicks in right way when hidden/fixed
		@include breakpoint(medium) {
			top: $header-height-medium;
			margin-top: -$header-height-medium;
		}
		&--is-hidden, &--is-fixed {
			position: fixed;
			top: 0 !important;
			bottom: auto!important;
			left: 0;
			right: 0;
			transition: margin-top .3s;
		}
		&--is-hidden {
			margin-top: -$header-height-small;
			@include breakpoint(smallish) {
				margin-top: -$header-height-medium;
			}
		}
		&--is-fixed {
			margin-top: 0;
			box-shadow: 0px 0px 20px 0 rgba(0,0,0,.15);
		}
	}
}
