.section__footer--ign {
	position: relative;

	.section-inner {
		@extend %gutters;
		@extend %text-column;

        @include breakpoint(medium) {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }
	}

	.heading {
        @include h5-font;
		margin-bottom: 4rem;
		color: #fff;
	}

	.section-wrap__img {
		display: none;

        @include breakpoint(medium) {
            display: flex;
            align-content: center;
            width: 35%;

            svg {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                margin-left: auto;
                margin-right: 0;
                path {
                    fill: #fff;
                }
            }
        }
	}
}